/* eslint react/jsx-props-no-spreading: "off" */
import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../../components/Layout';
import PageLoader from '../../components/Page';
import MainPage from './MainPage';
import strings from './strings.json';
 

const { pageTitleTemplate } = strings;
 
export default (props) => {
  const pageData = props?.pageContext?.pageData;
  const { title, description, handle } = pageData;
  const body = description
  let description_seo = pageData.description;
  const { storeName,storeDescription } = props.data.store.siteMetadata.gatsbyStorefrontConfig; 
  return (    
    <Layout header={true}> 
      <Helmet title={storeName} titleTemplate={pageTitleTemplate} defer={false}>
        <meta name={description_seo} content={storeDescription} />
        <meta name="description" content={description_seo} />
      </Helmet> 
      {/* <MainPage {...props} /> */}
      <PageLoader pageData={pageData} page={ title, body,handle} />
    </Layout>
  ); 
};

export const mainPageQuery = graphql`
  query _MainPageQuery{
    store: site { 
      siteMetadata {
        gatsbyStorefrontConfig {
          storeName
          storeDescription
          gatsbyImageProps {
            loading
            fadeIn
            durationFadeIn
          }
        }
      }
    }
  }
`;
